import { useState } from "react";
import MyPeopleTile from ".";
import { InputLabel, Typography, TextField } from "@mui/material";

export default function MyPeopleTileExamples() {
  const [dependentsCount, setDependentsCount] = useState<number>(0);

  return (
    <>
      <InputLabel htmlFor="dependentsCountID">
        <Typography>Dependents Count</Typography>
      </InputLabel>
      <TextField
        id="dependentsCountID"
        value={dependentsCount}
        onChange={(e) => setDependentsCount(parseInt(e.target.value))}
        fullWidth
        sx={{
          marginBottom: 2,
        }}
      />
      <MyPeopleTile
        membershipId={null}
        dependentsCount={dependentsCount}
        isLoading={false}
        isError={false}
      />
    </>
  );
}
