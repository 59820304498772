import { useTranslation } from "react-i18next";
import { useGauge } from "use-gauge";
import theme from "../../../../theme";
import { clampNumber, convertPxToRem } from "../../../../utils";
import { getHealthScoreRating } from "../../../../services/core-api-adapter";

const GAUGE_OPTIONS = {
  START_ANGLE: 90,
  END_ANGLE: 270,
  DIAMETER: 130,
  CHART_SECTION_OFFSET: 0,
  CHART_SECTION_STROKE_WIDTH: 34,
};

const healthScoreColorMap: any = {
  GOOD: theme.palette.accent1.main,
  AVERAGE: theme.palette.secondary[700],
  POOR: theme.palette.error.main,
};

export default function HealthScoreIndicatorGauge({
  healthScore,
}: {
  healthScore: number;
}) {
  const { t } = useTranslation();
  const min = 0;
  const max = 100;
  const clampedHealthScoreValue = clampNumber(healthScore, min, max);

  const healthScoreRating = getHealthScoreRating(clampedHealthScoreValue);

  const gauge = useGauge({
    domain: [min, max],
    startAngle: GAUGE_OPTIONS.START_ANGLE,
    endAngle: GAUGE_OPTIONS.END_ANGLE,
    diameter: GAUGE_OPTIONS.DIAMETER,
    numTicks: 0,
  });

  const setMarkerRotation = (healthScore: number): string => {
    if (healthScore === 0) {
      return "-90deg";
    } else {
      return "auto";
    }
  };

  const { viewBox } = gauge.getSVGProps();

  return (
    <div
      role="meter"
      aria-label={t("MyHealth.tabNavigation.myHealthProfileLink")}
      aria-valuenow={clampedHealthScoreValue}
      style={{ width: "fit-content" }}
    >
      <svg
        style={{ overflow: "visible", padding: "1rem" }}
        viewBox={viewBox}
        height="auto"
        width={convertPxToRem(110)}
      >
        <defs>
          <marker
            id="arrowhead"
            markerWidth="20"
            markerHeight="20"
            refX="0"
            refY="1.75"
            orient={setMarkerRotation(clampedHealthScoreValue)}
            overflow="visible"
          >
            <path
              d="M1.66456 15.1541L16.3387 18.2004C17.4125 18.4233 18.3552 17.4573 18.1062 16.3893L14.7032 1.79374C14.3666 0.350076 12.3638 0.215659 11.8373 1.6014L9.17614 8.60576C9.02826 8.995 8.72538 9.30536 8.33985 9.46268L1.40243 12.2937C0.0299295 12.8538 0.213116 14.8528 1.66456 15.1541Z"
              fill={healthScoreColorMap[healthScoreRating]}
              transform="scale(0.04) rotate(40)"
            />
          </marker>
        </defs>
        <g>
          <path
            {...gauge.getArcProps({
              offset: GAUGE_OPTIONS.CHART_SECTION_OFFSET,
              startAngle: GAUGE_OPTIONS.START_ANGLE,
              endAngle: GAUGE_OPTIONS.END_ANGLE,
            })}
            fill="none"
            stroke={theme.palette.neutral[100]}
            strokeLinecap="round"
            strokeWidth={GAUGE_OPTIONS.CHART_SECTION_STROKE_WIDTH}
          />

          <path
            key={`${min}-${max}`}
            {...gauge.getArcProps({
              offset: GAUGE_OPTIONS.CHART_SECTION_OFFSET,
              startAngle: gauge.valueToAngle(min),
              endAngle: gauge.valueToAngle(clampedHealthScoreValue),
            })}
            fill="none"
            stroke={healthScoreColorMap[healthScoreRating]}
            strokeLinecap="round"
            strokeWidth={GAUGE_OPTIONS.CHART_SECTION_STROKE_WIDTH}
            markerEnd="url(#arrowhead)"
          />
        </g>
        <text
          x="0"
          y="20"
          textAnchor="middle"
          style={{
            fontSize: convertPxToRem(36),
            fontWeight: 700,
            fill: healthScoreColorMap[healthScoreRating],
          }}
        >
          {clampedHealthScoreValue}
        </text>
      </svg>
    </div>
  );
}
