import { Stack, Typography } from "@mui/material";
import HealthProfileTile from ".";

export default function HealthProfileTileExamples() {
  return (
    <Stack spacing={2}>
      <Stack>
        <Typography>Has health score</Typography>
        <HealthProfileTile
          lastUpdatedDate="2022-03-15T10:30:00Z"
          healthScore={45}
          isLoading={false}
          isError={false}
        />
      </Stack>
      <Stack>
        <Typography>Does not have health score</Typography>
        <HealthProfileTile
          lastUpdatedDate="2022-03-15T10:30:00Z"
          healthScore={0}
          isLoading={false}
          isError={false}
        />
      </Stack>
      <Stack>
        <Typography>Is loading</Typography>
        <HealthProfileTile
          lastUpdatedDate="2022-03-15T10:30:00Z"
          healthScore={45}
          isLoading={true}
          isError={false}
        />
      </Stack>
      <Stack>
        <Typography>Has error</Typography>
        <HealthProfileTile
          lastUpdatedDate="2022-03-15T10:30:00Z"
          healthScore={45}
          isLoading={false}
          isError={true}
        />
      </Stack>
    </Stack>
  );
}
