import { Box, Divider, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import {
  trackEvent,
  trackUserInteraction,
} from "../../../../services/analytics-adapter";
import theme, { shadows } from "../../../../theme";
import { convertPxToRem } from "../../../../utils";
import { ChatBotOption } from "../../../../services/core-api-adapter";
import IconLoader from "@/features/Common/components/IconLoader";

export default function LifestyleAndAdviceSection() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  function onCallToActionClick(chatBotType: ChatBotOption) {
    trackEvent({
      event: "action.sweetLifeChatBotCallToActionClicked",
      source: "Home screen",
    });
    trackUserInteraction({
      linkText: `Dashboard | ${chatBotType} info tile`,
      linkIntent: "navigational",
      linkScope: "button",
    });
    navigate(`/home/sweet-life/chat/${chatBotType}`);
  }

  return (
    <>
      <Stack
        justifyContent="space-between"
        flexDirection="row"
        alignItems="center"
      >
        <Typography component="h2" fontWeight={600}>
          {t("ChatBot.dashboard.title")}
        </Typography>

        <Box
          sx={{
            p: 1,
            background: theme.palette.accent1.main,
            borderRadius: 2,
          }}
        >
          <Typography
            variant="body2"
            fontWeight={600}
            color="accent1.contrastText"
          >
            {t("ChatBot.dashboard.freeInfo")}
          </Typography>
        </Box>
      </Stack>

      <Stack
        direction="row"
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(2, 1fr)",
        }}
        gap={2}
      >
        <Stack
          onClick={() => {
            onCallToActionClick(ChatBotOption.DIABETES);
          }}
          component="button"
          aria-label={t("ChatBot.diabetesCard.title")}
          borderRadius={2.5}
          p={2}
          spacing={1}
          justifyContent="space-between"
          minHeight={convertPxToRem(195)}
          sx={{
            background: theme.palette.background.paper,
            boxShadow: shadows.light,
            cursor: "pointer",
            border: "none",
          }}
        >
          <Stack
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            textAlign="left"
            alignContent="space-between"
            width="100%"
            alignItems="center"
          >
            <Typography variant="body2" fontWeight="bold">
              {t("ChatBot.diabetesCard.title")}
            </Typography>
            <IconLoader icon="ChevronNextIcon" color="primary" />
          </Stack>
          <Stack alignSelf="center" pb={1}>
            <IconLoader
              icon="DiabetesIcon"
              sx={{
                fontSize: convertPxToRem(75),
              }}
            />
          </Stack>

          <Divider sx={{ width: "100%" }} />
          <Stack alignSelf="center">
            <Typography variant="body2" sx={{ fontSize: convertPxToRem(11) }}>
              {t("ChatBot.diabetesCard.subtitle")}
            </Typography>
          </Stack>
        </Stack>

        <Stack
          onClick={() => {
            onCallToActionClick(ChatBotOption.HEALTHY_LIVING);
          }}
          component="button"
          aria-label={t("ChatBot.healthyLiving.title")}
          borderRadius={2.5}
          p={2}
          spacing={1}
          justifyContent="space-between"
          minHeight={convertPxToRem(195)}
          sx={{
            background: theme.palette.background.paper,
            boxShadow: shadows.light,
            cursor: "pointer",
            border: "none",
          }}
        >
          <Stack
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            textAlign="left"
            alignContent="space-between"
            width="100%"
            alignItems="center"
          >
            <Typography variant="body2" fontWeight="bold">
              {t("ChatBot.healthyLiving.title")}
            </Typography>
            <IconLoader icon="ChevronNextIcon" color="primary" />
          </Stack>
          <Stack alignSelf="center" pb={1}>
            <IconLoader
              icon="HealthyLivingIcon"
              sx={{
                fontSize: convertPxToRem(75),
              }}
            />
          </Stack>

          <Divider sx={{ width: "100%" }} />
          <Stack alignSelf="center">
            <Typography variant="body2" sx={{ fontSize: convertPxToRem(11) }}>
              {t("ChatBot.healthyLiving.subtitle")}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
}
