import { createElement } from "react";
import {
  MembershipCardConfig,
  MembershipCardTemplateID,
  MembershipConfig,
  getMembershipConfig,
} from "../../../../services/core-api-adapter";
import Template1 from "./components/Template1";
import Template2 from "./components/Template2";
import Template3 from "./components/Template3";

interface MembershipCardProps {
  config: MembershipCardConfig;
}

function getMembershipCardTemplate(membershipConfig: MembershipConfig | null) {
  const templateLookupTable = [
    { id: MembershipCardTemplateID.TEMPLATE1, component: Template1 },
    { id: MembershipCardTemplateID.TEMPLATE2, component: Template2 },
    { id: MembershipCardTemplateID.TEMPLATE3, component: Template3 },
  ];

  return (
    templateLookupTable.find(
      (item) => item.id === membershipConfig?.cardTemplateID
    )?.component || Template2
  );
}

export default function MembershipCard({ config }: MembershipCardProps) {
  if (!config) {
    return <></>;
  }

  return (
    <>
      {createElement(
        getMembershipCardTemplate(getMembershipConfig(config.productCode)),
        {
          config: config,
        }
      )}
    </>
  );
}
