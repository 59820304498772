import HealthCheckTile from ".";
import { InputLabel, Typography, Stack } from "@mui/material";

export default function HealthCheckTileExamples() {
  return (
    <>
      <InputLabel htmlFor="dependentsCountID">
        <Typography>Available Health Checks Count</Typography>
      </InputLabel>
      <Stack>
        <Typography>isPAYGProduct</Typography>
        <HealthCheckTile
          isLoading={false}
          memberProfile={null}
          shouldShowServiceUsage={true}
        />
      </Stack>
      <Stack>
        <Typography>!isPAYGProduct</Typography>
        <HealthCheckTile
          isLoading={false}
          memberProfile={null}
          shouldShowServiceUsage={false}
        />
      </Stack>
      <Stack>
        <Typography>isCompatibleDevice</Typography>
        <HealthCheckTile
          isLoading={false}
          memberProfile={null}
          shouldShowServiceUsage={false}
        />
      </Stack>
      <Stack>
        <Typography>!isCompatibleDevice</Typography>
        <HealthCheckTile
          isLoading={false}
          memberProfile={null}
          shouldShowServiceUsage={false}
        />
      </Stack>
    </>
  );
}
