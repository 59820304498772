import { Button, ButtonProps } from "@mui/material";
import LoadingSpinner from "../LoadingSpinner";

interface ButtonWithLoadingStateProps {
  shouldShowLoadingSpinner?: boolean;
  spinnerColor?: string;
  spinnerSize?: number;
}

export default function ButtonWithLoadingState({
  shouldShowLoadingSpinner = true,
  spinnerColor,
  spinnerSize,
  ...props
}: Partial<ButtonWithLoadingStateProps & ButtonProps>) {
  return (
    <Button {...props}>
      {shouldShowLoadingSpinner && (
        <LoadingSpinner
          size={spinnerSize || 16}
          color={spinnerColor || "info"}
        />
      )}

      {!shouldShowLoadingSpinner && props.children && <>{props.children}</>}
    </Button>
  );
}
