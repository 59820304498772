import { useTranslation } from "react-i18next";
import { Stack, SxProps, Typography } from "@mui/material";
import { MembershipStatus } from "../../../../services/core-api-adapter";
import theme from "../../../../theme";
import IconLoader from "../../../Common/components/IconLoader";

interface MembershipStatusIndicatorProps {
  status: MembershipStatus;
  overrideStyles?: SxProps;
}

const statusViewModels = {
  ACTIVE: {
    icon: (
      <IconLoader
        icon="CheckInShieldIcon"
        sx={{
          fontSize: 16,
          color: "accent1.main",
        }}
      />
    ),
    textColour: "accent1.main",
    backgroundColour: "accent1.contrastText",
  },
  PENDING: {
    icon: (
      <IconLoader
        icon="ClockIcon"
        sx={{ fontSize: 16, color: (theme) => theme.palette.neutral[500] }}
      />
    ),
    textColour: "neutral.500",
    backgroundColour: "neutral.contrastText",
  },
  SUSPENDED: {
    icon: <IconLoader icon="PadlockIcon" color="error" sx={{ fontSize: 16 }} />,
    textColour: "error",
    backgroundColour: "error.contrastText",
  },
  CANCELLED: {
    icon: <IconLoader icon="PadlockIcon" color="error" sx={{ fontSize: 16 }} />,
    textColour: "error",
    backgroundColour: "error.contrastText",
  },
  EXPIRED: {
    icon: <IconLoader icon="PadlockIcon" color="error" sx={{ fontSize: 16 }} />,
    textColour: "error",
    backgroundColour: "error.contrastText",
  },
  UNKNOWN: {
    icon: (
      <IconLoader
        icon="InformationSymbolInCircleIcon"
        color={"neutral"}
        sx={{ fontSize: 16 }}
      />
    ),
    textColour: "neutral.main",
    backgroundColour: "neutral.contrastText",
  },
};

export default function MembershipStatusIndicator({
  status,
  overrideStyles,
}: MembershipStatusIndicatorProps) {
  const { t } = useTranslation();

  const statusData =
    status in statusViewModels
      ? statusViewModels[status]
      : statusViewModels.UNKNOWN;
  const text: string | undefined =
    status in statusViewModels
      ? t(`common.membershipStatus.${MembershipStatus[status]}`)
      : status;

  return (
    <Stack
      display="inline-flex"
      direction="row"
      alignItems="center"
      alignContent="center"
      alignSelf="flex-start"
      borderRadius={1}
      spacing={0.25}
      p={0.5}
      bgcolor={statusData.backgroundColour}
      sx={{
        border: `1px solid ${theme.palette.neutral[100]}`,
        height: "auto",
        width: "auto",
        ...overrideStyles,
      }}
    >
      {statusData.icon}
      {text && (
        <Typography
          fontSize={10}
          fontWeight="600"
          color={statusData.textColour}
        >
          {text}
        </Typography>
      )}
    </Stack>
  );
}
