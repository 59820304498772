import ItemPillSlider from "@/features/Common/components/ItemPillSlider";
import { DirectToDoctorPrimarySymptom } from "@/services/core-api-adapter";
import { useNavigate } from "react-router";
import DirectToDoctorPrimarySymptomsModal from "../DirectToDoctorPrimarySymptomsModal";
import { trackUserInteraction } from "@/services/analytics-adapter";
import { PRIMARY_SYMPTOM_PILL_MAP } from "@/features/Common/constants";

interface DirectToDoctorSliderProps {
  primarySymptoms: DirectToDoctorPrimarySymptom[];
  isModalOpen: boolean;
  setIsModalOpen: (arg: boolean) => void;
}

export default function DirectToDoctorSlider({
  primarySymptoms,
  isModalOpen,
  setIsModalOpen,
}: DirectToDoctorSliderProps) {
  const navigate = useNavigate();

  function onPrimarySymptomClick(primarySymptom: DirectToDoctorPrimarySymptom) {
    navigate(
      `/home/get-medical-assistance/intercare?primarySymptomIds=${primarySymptom.id}`
    );
  }

  function handleTrackInteraction(item: DirectToDoctorPrimarySymptom) {
    trackUserInteraction({
      linkText: `DirectToDoctorPrimarySymptomsSlider | ${item.name}`,
      linkIntent: "navigational",
      linkScope: "button",
    });
  }

  return (
    <>
      <ItemPillSlider
        items={primarySymptoms}
        itemMap={PRIMARY_SYMPTOM_PILL_MAP}
        onItemClick={onPrimarySymptomClick}
        trackInteraction={handleTrackInteraction}
      />

      <DirectToDoctorPrimarySymptomsModal
        primarySymptoms={primarySymptoms}
        itemMap={PRIMARY_SYMPTOM_PILL_MAP}
        onPrimarySymptomClick={onPrimarySymptomClick}
        isOpen={isModalOpen}
        onDialogClose={() => setIsModalOpen(false)}
      />
    </>
  );
}
