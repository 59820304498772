import { Box, Button, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import theme, { shadows } from "../../../../theme";
import IconLoader from "../IconLoader";
import { convertPxToRem } from "../../../../utils";

interface ItemPillProps {
  onClick: () => void;
  selectedItem: Record<string, any>;
  itemMap: Record<string, any>;
  shouldShowSubtitle?: boolean;
  titleStyles?: any;
  shouldShowArrow?: boolean;
  shouldShowPrice?: boolean;
}

export default function ItemPill({
  onClick,
  selectedItem,
  itemMap,
  shouldShowSubtitle = true,
  titleStyles,
  shouldShowArrow = true,
  shouldShowPrice = false,
}: ItemPillProps) {
  const { t } = useTranslation();

  const data = itemMap[selectedItem.panelChargeCode || selectedItem.name];

  return (
    <>
      {data ? (
        <Button
          key={data.title}
          variant="contained"
          color="info"
          aria-label={t(data.title)}
          sx={{
            justifyContent: "flex-start",
            flexDirection: "column",
            boxShadow: shadows.light,
            padding: 1,
            "&:hover, &:focus": {
              boxShadow: shadows.light,
              backgroundColor: theme.palette.primary[100],
            },
          }}
          fullWidth
          onClick={onClick}
        >
          <Stack
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            width="100%"
          >
            <Stack
              display="flex"
              flexDirection="row"
              alignItems="center"
              gap={2}
            >
              <Stack alignSelf="start">{data.icon}</Stack>
              <Stack textAlign="left" style={{ marginTop: 0 }}>
                <Typography
                  sx={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    ...titleStyles,
                  }}
                >
                  {t(data.title)}
                </Typography>
                {shouldShowSubtitle && (
                  <Typography fontSize={convertPxToRem(12)}>
                    {t(data.subtitle)}
                  </Typography>
                )}
                {shouldShowPrice && (
                  <Box
                    width="fit-content"
                    px={2}
                    py={0.5}
                    sx={{
                      backgroundColor: theme.palette.primary.light,
                      borderRadius: 2,
                      marginTop: 1,
                    }}
                  >
                    <Typography
                      fontWeight="bold"
                      color="primary.contrastText"
                      textAlign="center"
                    >
                      {selectedItem.amountTotal}
                    </Typography>
                  </Box>
                )}
              </Stack>
            </Stack>
            {shouldShowArrow && <IconLoader icon="ChevronNextIcon" />}
          </Stack>
        </Button>
      ) : null}
    </>
  );
}
