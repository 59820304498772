import IconLoader from "@/features/Common/components/IconLoader";
import { convertPxToRem } from "@/utils";

const iconStyles = {
  width: convertPxToRem(36),
  height: convertPxToRem(36),
};

export const PATHOLOGY_PANEL_PILL_MAP: { [key: string]: any } = {
  PATH_VIRAL_LOAD: {
    title: "PathologyPanel.viralLoad.title",
    subtitle: "PathologyPanel.viralLoad.subtitle",
    icon: (
      <IconLoader
        icon="HIVBloodDropIcon"
        sx={{ color: "accent4.main", ...iconStyles }}
      />
    ),
  },
  PATH_HBA1C: {
    title: "PathologyPanel.HBA1C.title",
    subtitle: "PathologyPanel.HBA1C.subtitle",
    icon: (
      <IconLoader
        icon="DiabetesCircleIcon"
        sx={{ color: "secondary.dark", ...iconStyles }}
      />
    ),
  },
  PATH_DIABETES: {
    title: "PathologyPanel.diabetesMonitoring.title",
    subtitle: "PathologyPanel.diabetesMonitoring.subtitle",
    icon: (
      <IconLoader
        icon="DiabetesCircleIcon"
        sx={{ color: "accent3.dark", ...iconStyles }}
      />
    ),
  },
  PATH_ANTENATAL: {
    title: "PathologyPanel.antenatal.title",
    subtitle: "PathologyPanel.antenatal.subtitle",
    icon: (
      <IconLoader
        icon="PregnancyIcon"
        sx={{ color: "accent1.main", ...iconStyles }}
      />
    ),
  },
  PATH_STD_EXCL_HIV: {
    title: "PathologyPanel.stdScreeningExlHiv.title",
    subtitle: "PathologyPanel.stdScreeningExlHiv.subtitle",
    icon: (
      <IconLoader
        icon="SexualTransmittedDiseasesIcon"
        sx={{ color: "secondary.main", ...iconStyles }}
      />
    ),
  },
  PATH_SYPHILIS: {
    title: "PathologyPanel.syphilis.title",
    subtitle: "PathologyPanel.syphilis.subtitle",
    icon: (
      <IconLoader
        icon="SexualTransmittedDiseasesIcon"
        sx={{ color: "accent2.main", ...iconStyles }}
      />
    ),
  },
  PATH_STD_PCR: {
    title: "PathologyPanel.stdPcr.title",
    subtitle: "PathologyPanel.stdPcr.subtitle",
    icon: <IconLoader icon="SexualTransmittedDiseasesIcon" sx={iconStyles} />,
  },
  PATH_HIV_SCREENING: {
    title: "PathologyPanel.hivScreening.title",
    subtitle: "PathologyPanel.hivScreening.subtitle",
    icon: (
      <IconLoader
        icon="HIVBloodDropIcon"
        sx={{ color: "secondary.main", ...iconStyles }}
      />
    ),
  },
  PATH_HEPATITIS_B: {
    title: "PathologyPanel.hepatitisB.title",
    subtitle: "PathologyPanel.hepatitisB.subtitle",
    icon: (
      <IconLoader
        icon="LiverIcon"
        sx={{ color: "accent5.main", ...iconStyles }}
      />
    ),
  },
  PATH_MALE_OVER_45: {
    title: "PathologyPanel.maleOver45.title",
    subtitle: "PathologyPanel.maleOver45.subtitle",
    icon: (
      <IconLoader
        icon="BloodDropsCircleIcon"
        sx={{ color: "accent1.main", ...iconStyles }}
      />
    ),
  },
  PATH_ANEAMIA: {
    title: "PathologyPanel.anaemia.title",
    subtitle: "PathologyPanel.anaemia.subtitle",
    icon: (
      <IconLoader
        icon="BloodDropIcon"
        sx={{ color: "accent4.main", ...iconStyles }}
      />
    ),
  },
  PATH_MALE_UNDER_45: {
    title: "PathologyPanel.maleUnder45.title",
    subtitle: "PathologyPanel.maleUnder45.subtitle",
    icon: (
      <IconLoader
        icon="BloodDropsCircleIcon"
        sx={{ color: "accent5.main", ...iconStyles }}
      />
    ),
  },
  PATH_HIV_MONITORING: {
    title: "PathologyPanel.hivMonitoring.title",
    subtitle: "PathologyPanel.hivMonitoring.subtitle",
    icon: (
      <IconLoader
        icon="HIVBloodDropIcon"
        sx={{ color: "primary.main", ...iconStyles }}
      />
    ),
  },
  PATH_STD_INCL_HIV: {
    title: "PathologyPanel.stdScreeningInclHiv.title",
    subtitle: "PathologyPanel.stdScreeningInclHiv.subtitle",
    icon: (
      <IconLoader
        icon="SexualTransmittedDiseasesIcon"
        sx={{ color: "accent3.main", ...iconStyles }}
      />
    ),
  },
  PATH_FEMALE: {
    title: "PathologyPanel.female.title",
    subtitle: "PathologyPanel.female.subtitle",
    icon: (
      <IconLoader
        icon="BloodDropsCircleIcon"
        sx={{ color: "accent4.main", ...iconStyles }}
      />
    ),
  },
  PATH_CHOLESTEROL: {
    title: "PathologyPanel.cholesterol.title",
    subtitle: "PathologyPanel.cholesterol.subtitle",
    icon: (
      <IconLoader
        icon="CholesterolCircleIcon"
        sx={{ color: "accent5.main", ...iconStyles }}
      />
    ),
  },
  PATH_BLOOD_PRESSURE: {
    title: "PathologyPanel.bloodPressure.title",
    subtitle: "PathologyPanel.bloodPressure.subtitle",
    icon: (
      <IconLoader
        icon="BloodPressureCircleIcon"
        sx={{ color: "accent1.main", ...iconStyles }}
      />
    ),
  },
};

export const PRIMARY_SYMPTOM_PILL_MAP: { [key: string]: any } = {
  SKIN_CONDITIONS: {
    title: "DirectToDoctor.primarySymptoms.skinConditions.title",
    subtitle: "DirectToDoctor.primarySymptoms.skinConditions.subtitle",
    icon: (
      <IconLoader
        icon="SkinConditionsIcon"
        sx={{ color: "accent4.main", ...iconStyles }}
      />
    ),
  },
  EYE_INFECTIONS: {
    title: "DirectToDoctor.primarySymptoms.eyeInfection.title",
    subtitle: "DirectToDoctor.primarySymptoms.eyeInfection.subtitle",
    icon: (
      <IconLoader
        icon="EyeInfectionIcon"
        sx={{ color: "secondary.dark", ...iconStyles }}
      />
    ),
  },
  BLADDER_INFECTION: {
    title: "DirectToDoctor.primarySymptoms.bladderInfection.title",
    subtitle: "DirectToDoctor.primarySymptoms.bladderInfection.subtitle",
    icon: (
      <IconLoader
        sx={{ color: "accent3.main", ...iconStyles }}
        icon="BladderInfectionIcon"
      />
    ),
  },
  COLD_FLU: {
    title: "DirectToDoctor.primarySymptoms.coldFlu.title",
    subtitle: "DirectToDoctor.primarySymptoms.coldFlu.subtitle",
    icon: (
      <IconLoader
        icon="ColdAndFluIcon"
        sx={{ color: "accent5.main", ...iconStyles }}
      />
    ),
  },
  VOMITING_DIARRHEA: {
    title: "DirectToDoctor.primarySymptoms.vomitingDiarrhoea.title",
    subtitle: "DirectToDoctor.primarySymptoms.vomitingDiarrhoea.subtitle",
    icon: (
      <IconLoader
        icon="VomitingAndDiarrhoeaIcon"
        sx={{ color: "accent1.main", ...iconStyles }}
      />
    ),
  },
  HEADACHE: {
    title: "DirectToDoctor.primarySymptoms.headache.title",
    subtitle: "DirectToDoctor.primarySymptoms.headache.subtitle",
    icon: (
      <IconLoader
        icon="HeadacheIcon"
        sx={{ color: "accent2.main", ...iconStyles }}
      />
    ),
  },
  BACK_PAIN: {
    title: "DirectToDoctor.primarySymptoms.backPain.title",
    subtitle: "DirectToDoctor.primarySymptoms.backPain.subtitle",
    icon: (
      <IconLoader
        icon="BackPainIcon"
        sx={{ color: "accent4.main", ...iconStyles }}
      />
    ),
  },
  CONTRACEPTION: {
    title: "DirectToDoctor.primarySymptoms.contraception.title",
    subtitle: "DirectToDoctor.primarySymptoms.contraception.subtitle",
    icon: (
      <IconLoader
        icon="ContraceptionIcon"
        sx={{ color: "secondary.dark", ...iconStyles }}
      />
    ),
  },
  GENITAL_INFECTIONS: {
    title: "DirectToDoctor.primarySymptoms.genitalInfections.title",
    subtitle: "DirectToDoctor.primarySymptoms.genitalInfections.subtitle",
    icon: (
      <IconLoader
        icon="SexualTransmittedDiseasesIcon"
        sx={{ color: "accent3.main", ...iconStyles }}
      />
    ),
  },
  GENERAL_CONDITIONS: {
    title: "DirectToDoctor.primarySymptoms.generalConditions.title",
    subtitle: "DirectToDoctor.primarySymptoms.generalConditions.subtitle",
    icon: (
      <IconLoader
        icon="GeneralDoctorConsultIcon"
        sx={{ color: "accent1.main", ...iconStyles }}
      />
    ),
  },
};
