import { useTranslation } from "react-i18next";
import IconLoader from "../IconLoader";

interface LoadingSpinnerProps {
  size?: number;
  color?: string;
}
export default function LoadingSpinner({ size, color }: LoadingSpinnerProps) {
  const { t } = useTranslation();

  return (
    <div
      role="progressbar"
      aria-busy="true"
      aria-live="polite"
      aria-label={t("common.loadingMessage")}
    >
      <IconLoader
        icon="SpinnerIcon"
        className="spin-animation"
        aria-label={t("common.loadingMessage")}
        sx={{
          color: color || "primary.main",
          fontSize: size || 64,
        }}
        strokeWidth="1"
      />
    </div>
  );
}
