import DefaultDialog, {
  DefaultDialogImplementationProps,
} from "@/features/Common/components/DefaultDialog";
import IconLoader from "@/features/Common/components/IconLoader";
import Panel from "@/features/Common/components/Panel";
import {
  MemberProductPaymentRatesChargeCode,
  MemberProductPaymentRatesItem,
} from "@/services/core-api-adapter";
import theme from "@/theme";
import { convertPxToRem } from "@/utils";
import {
  Button,
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";

function MedicalAssistanceInfoPanelModal({
  isOpen,
  onClose,
}: DefaultDialogImplementationProps) {
  const { t } = useTranslation();

  return (
    <DefaultDialog
      isOpen={isOpen}
      onClose={onClose}
      title={t("MedicalAssistanceInfoPanel.modal.title")}
      actionButtons={
        <Button size="small" onClick={() => onClose()}>
          {t("common.close")}
        </Button>
      }
    >
      <Typography variant="body1" color="neutral.main" align="center">
        {t("MedicalAssistanceInfoPanel.modal.body")}
      </Typography>
      <Stack
        spacing={2}
        textAlign="left"
        padding={2}
        borderRadius={2.5}
        sx={{ backgroundColor: theme.palette.accent2[100] }}
      >
        <Stack
          component="ul"
          role="list"
          display="flex"
          flexDirection="column"
          aria-label={t("MedicalAssistanceInfoPanel.modal.title")}
        >
          {(
            t<any, any, string[]>(
              "MedicalAssistanceInfoPanel.modal.listItems",
              {
                returnObjects: true,
              }
            ) as []
          ).map((listItemText: string) => (
            <ListItem
              role="listitem"
              sx={{ padding: "2px 0" }}
              key={listItemText}
            >
              <ListItemIcon sx={{ display: "contents" }}>
                <IconLoader
                  icon="CheckInCircleFilledIcon"
                  sx={{
                    fontSize: convertPxToRem(28),
                    color: "primary.main",
                    alignSelf: "center",
                  }}
                />
              </ListItemIcon>
              <ListItemText sx={{ pl: 0.5 }}>
                <Typography variant="body2">{listItemText}</Typography>
              </ListItemText>
            </ListItem>
          ))}
        </Stack>
      </Stack>
    </DefaultDialog>
  );
}

interface MedicalAssistanceInfoPanelProps {
  productPaymentRates: MemberProductPaymentRatesItem[];
}

export default function MedicalAssistanceInfoPanel({
  productPaymentRates,
}: MedicalAssistanceInfoPanelProps) {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);

  function onButtonClick() {
    setIsModalOpen(true);
  }

  function findAmountForService(type: MemberProductPaymentRatesChargeCode) {
    if (type === MemberProductPaymentRatesChargeCode.NURSE_CHAT) {
      return t("common.free");
    }

    return productPaymentRates.find((item) => item.chargeCode === type)
      ?.amountFormatted;
  }

  return (
    <>
      <Panel aria-label={t("MedicalAssistanceInfoPanel.ariaLabel")}>
        <Stack>
          <Stack display="flex" flexDirection="row" gap={1} mb={1}>
            <IconLoader
              icon="StethoscopeIcon"
              color="primary"
              sx={{
                fontSize: convertPxToRem(20),
              }}
            />

            <Typography variant="body1" fontWeight="bold">
              {t("MedicalAssistanceInfoPanel.title")}
            </Typography>
          </Stack>
          <Stack
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
          >
            <Typography variant="body2" color="neutral.main" fontWeight="bold">
              {t(`MedicalAssistanceInfoPanel.NURSE_CHAT.title` as any)}
            </Typography>
            <Typography color="primary.main" fontWeight="bold">
              {findAmountForService(
                MemberProductPaymentRatesChargeCode.NURSE_CHAT
              )}
            </Typography>
          </Stack>
          <Stack
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
          >
            <Stack>
              <Typography
                variant="body2"
                color="neutral.main"
                fontWeight="bold"
              >
                {t(
                  `MedicalAssistanceInfoPanel.REMOTE_DOCTOR_CONSULTATIONS.title` as any
                )}
              </Typography>
            </Stack>
            <Typography
              color="primary.main"
              fontWeight="bold"
              textAlign="right"
            >
              {findAmountForService(
                MemberProductPaymentRatesChargeCode.REMOTE_DOCTOR_CONSULTATIONS
              )}
            </Typography>
          </Stack>
        </Stack>
        <Stack spacing={1} py={1}>
          <Stack display="flex" flexDirection="row" gap={1}>
            <IconLoader
              icon="SyringeIcon"
              color="primary"
              sx={{
                fontSize: convertPxToRem(20),
              }}
            />

            <Typography variant="body1" fontWeight="bold">
              {t("MedicalAssistanceInfoPanel.otherServices.title")}
            </Typography>
          </Stack>
          <Stack
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
          >
            <Stack>
              <Typography
                variant="body2"
                color="neutral.main"
                fontWeight="bold"
              >
                {t(`MedicalAssistanceInfoPanel.LABORATORY.title` as any)}
              </Typography>
            </Stack>
            <Typography variant="body2" color="primary.main" textAlign="right">
              {t(`MedicalAssistanceInfoPanel.LABORATORY.subtitle` as any)}
            </Typography>
          </Stack>
          <Stack
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
          >
            <Stack>
              <Typography
                variant="body2"
                color="neutral.main"
                fontWeight="bold"
              >
                {t(`MedicalAssistanceInfoPanel.PHARMACY.title` as any)}
              </Typography>
            </Stack>
            <Typography variant="body2" color="primary.main" textAlign="right">
              {t(`MedicalAssistanceInfoPanel.PHARMACY.subtitle` as any)}
            </Typography>
          </Stack>
          <Stack
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
          >
            <Typography variant="body2" color="neutral.main" fontWeight="bold">
              {t(`MedicalAssistanceInfoPanel.CLINIC.title` as any)}
            </Typography>
            <Typography
              color="primary.main"
              fontWeight="bold"
              textAlign="right"
            >
              {findAmountForService(MemberProductPaymentRatesChargeCode.CLINIC)}
            </Typography>
          </Stack>
        </Stack>
        <Divider />
        <Stack>
          <Button
            variant="text"
            onClick={onButtonClick}
            size="small"
            sx={{ fontSize: convertPxToRem(14), alignSelf: "center" }}
          >
            {t("MedicalAssistanceInfoPanel.modal.title")}
          </Button>
        </Stack>
      </Panel>
      <MedicalAssistanceInfoPanelModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
    </>
  );
}
