import { Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import MaxWidthContainer from "../MaxWidthContainer";
import Navigation from "../Navigation";

export default function BottomNavigation() {
  const { t } = useTranslation();

  return (
    <Stack
      direction="row"
      sx={{
        position: "fixed",
        left: "50%",
        transform: "translateX(-50%)",
        bottom: 0,
        width: "100%",
        p: (theme) => theme.spacing(1),
        pt: 0,
        zIndex: 2,
      }}
      component="nav"
      aria-label={t("BottomNavigation.label")}
    >
      <MaxWidthContainer>
        <Navigation />
      </MaxWidthContainer>
    </Stack>
  );
}
